<template>
  <div class="topic">
    <BackBar :pageTit="headTit" />
    <vue-scroll
      class="main-page"
      :ops="ops"
      @refresh-start="handleRefresh"
      @load-start="handleLoad"
      ref="vs"
    >
        <CommentList :item="posts" :commentType="commentType" />
    </vue-scroll>
  </div>
</template>

<script>
import BackBar from "@/components/BackBar.vue";
import Api from "@/api";
import Vue from "vue";
import CommentList from "@/components/CommentList.vue";

export default {
  name: "MyComment",
  data() {
    const ops = {
      vuescroll: {
        mode: "slide",
        pullRefresh: {
          enable: true,
        },
        pushLoad: {
          enable: true,
        },
      },
    };

    ops.vuescroll.pullRefresh.tips = {
      deactive: "下拉刷新",
      active: "释放刷新",
      start: "刷新中...",
      beforeDeactive: "刷新成功!",
    };
    ops.vuescroll.pushLoad.tips = {
      deactive: "上拉加载",
      active: "释放加载",
      start: "加载中...",
      beforeDeactive: "加载成功!",
    };

    return {
      posts: [],
      page: 1,
      ops: ops,
      isloading: false,
      topic: {},
      headTit:'我的评论',
      commentType:1
    };
  },
  components: {
    BackBar,
    CommentList,
  },
  methods: {
    handleRefresh(vm, refreshDom, done) {
      if (this.isloading) {
        return;
      }
      console.log("refresh");
      this.page = 1;
      this.posts = [];
      this.refreshPost(done);
    },
    handleLoad(vm, refreshDom, done) {
      if (this.isloading) {
        return;
      }
      console.log("handleLoad");
      this.refreshPost(done);
    },
    refreshPost(cb) {
      var that = this;
      this.isloading = true;

      Api.go("my_comment", { page: this.page++ }, (res) => {
        var _ = that.posts;
        console.log(res)
        if (res.length > 0) {
          for (var i in res) {
            _.push(res[i]);
          }
        } else {
          Vue.prototype.$toast("暂无更多");
        }
        if (cb) {
          cb();
        }
        that.isloading = false;
        setTimeout(() => {
          that.$refs.vs.refresh();
        }, 100);
      });
    },
  },
  mounted() {
    this.refreshPost();
  },
};
</script>

<style lang="less" scoped>
.topic {
  padding-bottom: 1rem;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

</style>